import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {




    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        // {
        //     UserName: "Jeanne T. San Dimas, CA",
        //     Date: "10/18/2023",
        //     Rate: 5.0,
        //     Description: "Professional & knowledgeable. Eli was very easy to work with and they did a wonderful job from start to finish. I definitely recommend them. It was a pleasure doing business with kind, honest reliable gentlemen.",
        //     red: "HomeAdvisor",
        //     enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
        //     ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        // },
        {
            UserName: "Cliente 1",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "We hired Luis to remodel our master shower and we couldn't be more pleased! His team was professional, clean, and on time. They were able to give us a quality product at a fair price. We will definitely use Luis and the team again!"
        },
        {
            UserName: "Cliente 2",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "Luis contacted me within 5 minutes of me requesting a quote. He met me immediately to begin the project after I told him I was in a crunch for time. The project was completed quickly and looked great and his price was very very reasonable. I plan on contacting Luis Flooring again to handle a few other projects I have and would recommend him to anyone."
        },
        {
            UserName: "Cliente 3",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "First let me say he is hard working, very responsive to questions and phone calls. He completed our project in one day and was helpful on which color would look best for our grout. I couldn't believe my eyes when I saw the finished product. Our tiles looked brand new. I would hire him again!"
        },
        {
            UserName: "Cliente 4",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "Wonderful experience!!!! Luis and his team are true professionals, they made this job easy and painless. I needed to replace three 30-year-old toilets and a portion of my kitchen ceiling where one of the toilets was leaking from. The finished product was so great I would not be able to locate the damage if I didn’t know/see the damage before. Must hire this team for your project large or small."
        },
        {
            UserName: "Cliente 5",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "Quality work and finishing, extremely clean and careful while working. Really good communication throughout."
        },
        {
            UserName: "Cliente 6",
            Rate: 5.0,
            Quality: 5.0,
            Responsiveness: 5.0,
            Punctuality: 5.0,
            Professionalism: 5.0,
            Description: "The marble floor in my bathroom looks so beautiful. They removed the old grout, re-grouted and sealed the floor. I think the floor looks better than when it was new. I will be having them come back to do more floors in my house."
        }
    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        {/* <span className="text-[20px] font-semibold"> {item.UserName} </span> */}
                                        <Stars score={item.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.Description}</ReadMore>
                            </div>
                            {/* <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="red" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div> */}
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;